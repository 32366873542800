module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Commerz Building & Management","short_name":"Commerz Building","start_url":"/","background_color":"#ffcc33","theme_color":"#ffcc33","display":"browser","icon":"src/images/commerzbank-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
